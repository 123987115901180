import React, { useEffect } from "react"
import Container from "components/Container"
import styles from "./HubspotForm.module.css"

export const loadScript = (src) =>
  new Promise((resolve, reject) => {
    const $script = document.createElement(`script`)
    $script.onload = resolve
    $script.onerror = reject
    $script.src = src
    $script.defer = true
    document.head.appendChild($script)
  })

export const initializeHubspotForm = (formId, portalId) =>
  window.hbspt.forms.create({
    formId,
    portalId,
    target: `[data-hubspot-form="${formId}"]`,
  })

export const HubspotForm = ({ id, portalId }) => {
  useEffect(() => {
    if (!window.hbspt) {
      loadScript(`//js.hsforms.net/forms/shell.js`).then(() => initializeHubspotForm(id, portalId))
    } else {
      initializeHubspotForm(id, portalId)
    }
  }, [id, portalId])

  return (
    <Container className={styles.root}>
      <div data-hubspot-form={id} />
    </Container>
  )
}

export default HubspotForm
