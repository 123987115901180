import React from "react"
import cx from "classnames"
import { Helmet } from "react-helmet"
import * as Header from "components/Header"
import * as Footer from "components/Footer"
import * as Schema from "components/Schema"
import "./Layout.css"
require("prismjs/themes/prism.css")
require("prismjs/plugins/line-numbers/prism-line-numbers.css")

export const Layout = ({
  isLight,
  isMobileHeaderHidden,
  isMobileFooterHidden,
  noStaticQueries,
  layoutData,
  children,
  location,
  seo,
  ...props
}) => {
  seo = seo || { title: "OpsGuru", description: "OpsGuru", image: {} }

  return (
    <div
      className={cx({
        "header-m-hidden": isMobileHeaderHidden,
        "footer-m-hidden": isMobileFooterHidden,
      })}
    >
      <Helmet>
        <html lang="en" />
        <title itemProp="name" lang="en">
          {seo.title}
        </title>
        <meta name="title" content={seo.title} />
        <meta name="description" content={seo.description} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://opsguru.io${location.pathname}`} />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={(seo.image || {}).publicURL} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={`https://opsguru.io${location.pathname}`} />
        <meta property="twitter:title" content={seo.title} />
        <meta property="twitter:description" content={seo.description} />
        <meta property="twitter:image" content={(seo.image || {}).publicURL} />

        {seo.canonical ? (
          <link rel="canonical" key={seo.canonical} href={seo.canonical} />
        ) : null}

        {seo.noindex ? (
          <meta name="robots" content="noindex, nofollow" />
        ) : null}

        {location.pathname === '/' ? (
          <script type="application/ld+json">
            {JSON.stringify(Schema.homepage)}
          </script>
        ) : null}

        {location.pathname === '/aws-summit-toronto' ? (
          <script type="application/ld+json">
            {JSON.stringify(Schema.awsSummitToronto)}
          </script>
        ) : null}
      </Helmet>

      {noStaticQueries ? (
        <Header.Header isLight={isLight} data={layoutData} location={location} />
      ) : (
        <Header.HeaderWrapper isLight={isLight} location={location} layout={props.layout} />
      )}
      <main>{children}</main>
      {noStaticQueries ? (
        <Footer.Footer data={layoutData} location={location} />
      ) : (
        <Footer.FooterWrapper location={location} layout={props.layout} />
      )}
    </div>
  )
}

export default Layout
