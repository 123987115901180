export const homepage = {
	  "@context": "http://schema.org",
	  "@type": "Organization",
	  "address": {
	  "@type": "PostalAddress",
	  "addressLocality": "Vancouver",
	  "addressRegion": "BC",
	  "postalCode":"V6B 1N2",
	  "streetAddress": "675 W Hastings St 9th Floor,"
	  },
	  "areaServed": ["Canada", "Israel", "Romania"],
	  "description": "OpsGuru is a certified AWS, Google Cloud & Microsoft Azure global partner specialized in Cloud Adoption, Application Modernization, Kubernetes Enablement and Data Analytics services.",
	  "telephone": "1-778-800-2468",
	  "name": "Ops Guru",
	  "image": "https://mma.prnewswire.com/media/1606059/OpsGuru_OpsGuru_Announces_Expansion_to_Central_and_Eastern_Canad.jpg?w=500",
	      "url": "https://opsguru.io",
	  "sameAs" : [ "https://twitter.com/opsguruteam, https://github.com/ops-guru, https://www.linkedin.com/company/opsguruteam/"]
}

export const awsSummitToronto = {
	  "@context": "https://schema.org",
	  "@type": "Event",
	  "name": "AWS Summit Toronto 2023",
	  "startDate": "2023-06-14T00:00-04:00",
	  "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
	  "eventStatus": "https://schema.org/EventScheduled",
	  "location": {
	    "@type": "Place",
	    "name": "Metro Toronto Convention Centre - South Building",
	    "address": {
	      "@type": "PostalAddress",
	      "streetAddress": "222 Bremner Blvd",
	      "addressLocality": "Toronto",
	      "postalCode": "M5J 2L2",
	      "addressRegion": "ON",
	      "addressCountry": "CA"
	    }
	  },
	  "description": "AWS Summits are a series of free online and in-person events that bring the cloud computing community together to connect, collaborate, and learn about AWS. These events are designed to educate you about AWS products and services and help you develop the skills needed to build, deploy, and operate your infrastructure and applications. Sessions are delivered by AWS subject matter experts, Partners, and customers who have successfully built solutions on AWS. Whether you are new to the cloud or an experienced user, you can learn how to innovate faster and securely at lower cost to build, run, and scale all of your applications in the cloud, on premises, and at the edge."
}